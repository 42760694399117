<template>
	<main>
		<el-dialog :visible="true" @close="close">
			<template slot="title">
				<div class="title">{{query.id ? '编辑' : '创建'}}{{ title }}</div>
			</template>
			<div class="form-box">
				<el-form class="create-form" ref="formRef" :rules="rules" :model="form" label-width="100px">
					<el-form-item label="名称" prop="title">
						<el-input v-model="form.title"/>
					</el-form-item>
					<el-form-item label="备注" prop="remark" >
						<el-input v-model="form.remark"/>
					</el-form-item>
					<el-form-item label="状态" prop="status">
						<el-radio-group v-model="form.status">
							<el-radio v-for="(item,index) in column_option.status" :key="index" :label="item.id">{{item.title}}</el-radio>
						</el-radio-group>
					</el-form-item>
				</el-form>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="close">取 消</el-button>
				<el-button type="primary" :loadin="submit_loading" @click="submit">保 存</el-button>
			</div>
		</el-dialog>
	</main>
</template>

<script>
import {category as api} from "@/api/admin";
import {MixinCreate} from "@/service/admin/mixinCreate";
export default {
	mixins:[MixinCreate],
	data(){
		return {
			api,
		}
	},
	computed:{
		rules(){
			return {
				title:[
					{required:true,message:"请输入名称"}
				],
			}
		}
	},
}
</script>
